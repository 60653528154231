import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import "./TranslationTaskDashboardCss.css";
import LoadingState from "./LoadingState";
import ErrorState from "./ErrorState";
import NoDataState from "./NoDataState";
import SearchableLanguageSelect from "./SearchLanguageSelect";
import LanguageChangeModal from "./TranslationLanguageConfirmationModal";

import { showNotification } from "../../actions/index.actions";

const TranslationDashboard = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextCursor, setNextCursor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [translation, setTranslation] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [pendingCount, setPendingCount] = useState(0);
  const [showLanguageChangeModal, setShowLanguageChangeModal] = useState(false);
  const [pendingLanguageChange, setPendingLanguageChange] = useState(null);
  const [todayCompletedCount, setTodayCompletedCount] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "en",
    label: "English",
  });

  const employeeId = localStorage.getItem("employeeId");

  const languageOptions = [
    { value: "hi", label: "हिंदी (Hindi)" },
    { value: "bn", label: "বাংলা (Bengali)" },
    { value: "mr", label: "मराठी (Marathi)" },
    { value: "te", label: "తెలుగు (Telugu)" },
    { value: "ta", label: "தமிழ் (Tamil)" },
    { value: "gu", label: "ગુજરાતી (Gujarati)" },
    { value: "ur", label: "اردو (Urdu)" },
    { value: "kn", label: "ಕನ್ನಡ (Kannada)" },
    { value: "ml", label: "മലയാളം (Malayalam)" },
    { value: "pa", label: "ਪੰਜਾਬੀ (Punjabi)" },
    { value: "as", label: "অসমীয়া (Assamese)" },
    { value: "or", label: "ଓଡ଼ିଆ (Odia)" },
    { value: "en", label: "English" },
  ];

  // Initialize KeymanWeb - moved outside of dependencies
  useEffect(() => {
    let keymanInitialized = false;

    const initKeyboard = () => {
      try {
        if (window.keyman) {
          // Basic initialization
          window.keyman.init({
            attachType: "manual",
            displayUnderlying: false,
          });

          // Attach to textarea with input event handling
          const translationInput = document.querySelector(".translation-input");
          if (translationInput) {
            window.keyman.attachToControl(translationInput);

            // Add KeymanWeb specific input handler
            window.keyman.addEventListener("keyboardchange", () => {
              // Update React state with current input value
              setTranslation(translationInput.value);
            });

            // Add input event listener for KeymanWeb input
            translationInput.addEventListener("input", (e) => {
              setTranslation(e.target.value);
            });
          }

          window.keyman.addKeyboards("@en");
          keymanInitialized = true;

          // Style the keyboard button
          setTimeout(() => {
            const keyboardButton = document.querySelector(".kmw-keyboard-icon");
            if (keyboardButton) {
              keyboardButton.classList.add("custom-keyboard-button");
              keyboardButton.title = "Toggle keyboard";
            }
          }, 100);
        }
      } catch (err) {
        console.error("Failed to initialize KeymanWeb:", err);
        showNotification("Error", "Failed to initialize keyboard", "danger");
      }
    };

    let attempts = 0;
    const maxAttempts = 50;
    const interval = setInterval(() => {
      if (window.keyman) {
        clearInterval(interval);
        initKeyboard();
      } else if (attempts >= maxAttempts) {
        clearInterval(interval);
        console.error("KeymanWeb failed to load");
        showNotification("Error", "Failed to load keyboard", "danger");
      }
      attempts++;
    }, 100);

    // Cleanup
    return () => {
      clearInterval(interval);
      try {
        if (keymanInitialized && window.keyman) {
          const translationInput = document.querySelector(".translation-input");
          if (translationInput) {
            // Remove event listeners
            window.keyman.removeEventListener("keyboardchange");
            translationInput.removeEventListener("input", () => {});
            window.keyman.detachFromControl(translationInput);
          }
          window.keyman.setActiveKeyboard("");
        }
      } catch (err) {
        console.error("Error during cleanup:", err);
      }
    };
  }, []);

  // Modified handleLanguageChange to reinitialize event listeners
  const handleLanguageChange = async (e) => {
    if (translation.trim()) {
      // If there's text in the translation box, show confirmation modal
      setPendingLanguageChange(e.target);
      setShowLanguageChangeModal(true);
    } else {
      // If no text, proceed with language change
      await changeLanguage(e.target);
    }
  };

  const changeLanguage = async (target) => {
    const langObj = languageOptions.find((lang) => lang.value === target.value);
    setSelectedLanguage(langObj);
    setTranslation(""); // Clear the translation

    if (window.keyman && typeof window.keyman.addKeyboards === "function") {
      try {
        await window.keyman.addKeyboards("@" + target.value);
        const keyboards = window.keyman.getKeyboards();
        const keyboard = keyboards.find(
          (kb) => kb.LanguageCode === target.value
        );

        if (keyboard) {
          window.keyman.setActiveKeyboard(keyboard.InternalName);
          const translationInput = document.querySelector(".translation-input");
          if (translationInput) {
            window.keyman.attachToControl(translationInput);
            translationInput.addEventListener("input", (e) => {
              setTranslation(e.target.value);
            });
            translationInput.focus();
          }
        }
      } catch (err) {
        console.error("Error changing keyboard:", err);
        showNotification("Error", "Failed to change keyboard", "danger");
      }
    }
  };

  const fetchTasks = async (cursor = null) => {
    if (!hasMore && cursor) return;

    try {
      if (cursor) {
        setIsLoadingMore(true);
      } else {
        setLoading(true);
      }
      setError(null);

      const params = new URLSearchParams({
        employeeId: employeeId,
        limit: "10",
      });
      if (cursor) {
        params.append("cursor", cursor);
      }

      const response = await api.get(
        `/api/employee/translation-tasks?${params}`,
        {
          headers: { "Cache-Control": "no-cache" },
        }
      );

      if (
        response.data.message ===
        "No translation tasks found for the specified criteria."
      ) {
        setHasMore(false);
        return;
      }

      const {
        data,
        nextCursor: newCursor,
        pendingCount,
        todayCompletedCount,
      } = response.data;

      if (Array.isArray(data) && data.length > 0) {
        setTasks((prevTasks) => {
          const newTasks = data.filter(
            (newTask) =>
              !prevTasks.some(
                (prevTask) =>
                  prevTask.taskId === newTask.taskId &&
                  prevTask.targetLanguage === newTask.targetLanguage
              )
          );
          return [...prevTasks, ...newTasks];
        });
        setNextCursor(newCursor);
        setHasMore(!!newCursor);
        setPendingCount(pendingCount);
        setTodayCompletedCount(todayCompletedCount);
      } else {
        setHasMore(false);
      }
    } catch (err) {
      showNotification("Error", "Failed to fetch translation tasks", "danger");
      setError(err);
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    if (!employeeId) {
      navigate("/sign-in");
      return;
    }
    fetchTasks();
  }, [employeeId, navigate]);

  const handleNext = async () => {
    setTranslation("");
    if (currentIndex === tasks.length - 1) {
      if (hasMore && nextCursor) {
        try {
          await fetchTasks(nextCursor);
          setCurrentIndex(tasks.length);
        } catch (err) {
          console.error("Error loading more tasks:", err);
          showNotification("Error", "Failed to load more tasks", "danger");
        }
      }
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setTranslation("");
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const currentTask = tasks[currentIndex];
      const response = await api.post(
        "/api/employee/submit-translation",
        {
          taskId: currentTask.taskId,
          translation: translation,
          employeeId: employeeId,
          translationLanguageId: currentTask.translationLanguageId,
        },
        {
          headers: { "Cache-Control": "no-cache" },
        }
      );

      if (response.status === 200) {
        if (response.data.pendingCount !== undefined) {
          setPendingCount(response.data.pendingCount);
        }
        if (response.data.todayCompletedCount !== undefined) {
          setTodayCompletedCount(response.data.todayCompletedCount);
        }

        showNotification(
          "Success",
          "Translation submitted successfully",
          "success"
        );
        setTranslation("");

        setTasks((prevTasks) => {
          const newTasks = prevTasks.filter(
            (task) =>
              !(
                task.taskId === currentTask.taskId &&
                task.targetLanguage === currentTask.targetLanguage
              )
          );
          if (currentIndex >= newTasks.length) {
            setCurrentIndex(Math.max(0, newTasks.length - 1));
          }
          return newTasks;
        });

        if (tasks.length <= 5 && hasMore && nextCursor) {
          fetchTasks(nextCursor);
        }
      }
    } catch (err) {
      showNotification(
        "Error",
        "Failed to submit translation. Please try again.",
        "danger"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // State handling using components
  if (loading && tasks.length === 0) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState error={error} />;
  }

  if (tasks.length === 0) {
    return <NoDataState />;
  }

  const currentTask = tasks[currentIndex];
  const isLastTask = currentIndex === tasks.length - 1 && !hasMore;

  return (
    <div className="container">
      <div className="main-content">
        <div className="text-container">
          <h2 className="header">Main Text to be translated</h2>
          <div className="original-text">{currentTask.originalText}</div>
        </div>

        <div className="info-container">
          <h2 className="header">Info about the translation task</h2>
          <div className="task-info">
            <p>
              <strong>Category:</strong> {currentTask.categoryName}
            </p>
            <p>
              <strong>Description:</strong> {currentTask.categoryDescription}
            </p>
            <p>
              <strong>Original Language:</strong> {currentTask.originalLanguage}
            </p>
            <p>
              <strong>Target Language:</strong>{" "}
              <span className="target-language-badge">
                {currentTask.targetLanguage}
              </span>
            </p>
            <p>
              <strong>Assigned At:</strong>{" "}
              {new Date(currentTask.assignedAt).toLocaleString()}
            </p>
            <div className="counts-container">
              <div className="count-item">
                <span className="count-label">Pending:</span>
                <span className="count-value">{pendingCount}</span>
              </div>
              <div className="count-item">
                <span className="count-label">Today:</span>
                <span className="count-value">{todayCompletedCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="translation-container">
        <div className="language-selector">
          <SearchableLanguageSelect
            selectedLanguage={selectedLanguage}
            onLanguageChange={handleLanguageChange}
            languageOptions={languageOptions}
          />
        </div>
        <textarea
          className="translation-input kmw-input"
          placeholder="Enter your translation here..."
          value={translation}
          onChange={(e) => setTranslation(e.target.value)}
          onInput={(e) => setTranslation(e.target.value)} // Added onInput handler
        />
      </div>

      <div className="navigation">
        <button
          className={`button previous-button ${
            currentIndex === 0 || isSubmitting ? "disabled" : ""
          }`}
          onClick={handlePrevious}
          disabled={currentIndex === 0 || isSubmitting}
        >
          Previous
        </button>
        <button
          className="button submit-button"
          onClick={handleSubmit}
          disabled={!translation.trim() || isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        <button
          className={`button next-button ${
            isLastTask || isSubmitting || isLoadingMore ? "disabled" : ""
          }`}
          onClick={handleNext}
          disabled={isLastTask || isSubmitting || isLoadingMore}
        >
          {isLoadingMore ? "Loading..." : "Next"}
        </button>
      </div>
      <LanguageChangeModal
        isOpen={showLanguageChangeModal}
        onClose={() => {
          setShowLanguageChangeModal(false);
          setPendingLanguageChange(null);
        }}
        onConfirm={async () => {
          if (pendingLanguageChange) {
            await changeLanguage(pendingLanguageChange);
          }
          setShowLanguageChangeModal(false);
          setPendingLanguageChange(null);
        }}
      />
    </div>
  );
};

export default TranslationDashboard;
