import React, { useState } from "react";
import "./EmployeeLoginStyles.css";
import logo from "../../Image/logo3.png";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../actions/index.actions";
import api from "../../api";
import FullpageLoader from "../../elements/FullpageLoader";

const EmployeeLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState([]); // State to store fetched menu
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      showNotification(
        "Missing Information",
        "Please enter both email and password",
        "warning"
      );
      return;
    }
    try {
      setLoading(true); // Start loading
      const response = await api.post(
        "/api/employee/sign-in",
        {
          email,
          password,
        },
        {
          headers: { "Cache-Control": "no-cache" },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        // Fetch empId from response and store user data
        const { empId, fullName} = data;
        localStorage.setItem("userName", fullName || "User");
        localStorage.setItem("employeeId", empId);
        showNotification(
          "Success",
          `Welcome back, ${fullName || "User"}!`,
          "success"
        );
        // Fetch menu access
        const menuResponse = await api.get(`/api/employee/menu-access`, {
          params: { empId },
        }, {
          headers: { "Cache-Control": "no-cache" },
        });

        if (menuResponse.status === 200) {
          const fetchedMenu = menuResponse.data.menuAccess;
          setMenu(fetchedMenu); // Store menu in state
          localStorage.setItem("menuAccess", JSON.stringify(fetchedMenu)); // Optional: Store in local storage
        }

        navigate("/");
      } else {
        showNotification(
          "Unexpected Response",
          "An unexpected response was received. Please try again later.",
          "warning"
        );
      }
    } catch (error) {
      // Handle different types of errors with appropriate messages
      if (error.response?.status === 401) {
        showNotification(
          "Authentication Failed",
          "Invalid email or password",
          "danger"
        );
      } else if (error.response?.status === 404) {
        showNotification("Not Found", "User account not found", "danger");
      } else {
        showNotification(
          "Error",
          error.response?.data?.message || "Login failed. Please try again.",
          "danger"
        );
      }
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  if (loading) {
    return <FullpageLoader isOpen={loading} />;
  }

  return (
    <div className="login-page">
      {/* Left Panel */}
      <div className="login-left-panel">
        <div className="left-content">
          <div className="logo-container">
            <img src={logo} alt="The Data Tasker Logo" className="logo" />
          </div>
          <h1>Welcome To The Data Tasker</h1>
        </div>
      </div>

      {/* Right Panel */}
      <div className="login-right-panel">
        <div className="login-form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@example.com"
                required
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="••••"
                required
              />
            </div>

            <button type="submit" className="login-button">
              LOGIN
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployeeLogin;
