import React from "react";
import Modal from "react-modal";
import "./NotificationConfirmationModalCss.css";

const NotificationConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  userCount,
  selectedComment,
  additionalText,
  isSubmitting,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="notification-modal"
      overlayClassName="notification-modal-overlay"
      ariaHideApp={false}
    >
      <div className="notification-modal-content">
        <h3 className="notification-modal-title">Confirm Notification</h3>
        <div className="notification-modal-message">
          <p className="user-count">
            You are sending the notification to <strong>{userCount}</strong>{" "}
            users.
          </p>
          <div className="message-preview">
            <h4>Selected Message:</h4>
            <p>{selectedComment}</p>

            {additionalText && (
              <>
                <h4>Additional Message:</h4>
                <p>{additionalText}</p>
              </>
            )}
          </div>
          <p className="confirmation-text">
            Are you sure you want to continue?
          </p>
        </div>

        <div className="notification-modal-buttons">
          <button
            onClick={onClose}
            disabled={isSubmitting}
            className="modal-button cancel-button"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={isSubmitting}
            className="modal-button confirm-button"
          >
            {isSubmitting ? "Sending..." : "Confirm"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationConfirmModal;
