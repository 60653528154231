import React, { Component } from "react";
import styled from "styled-components";
import AltryexTypeSelect from "../../elements/dropdowns/AltryexTypeSelect";
import { showNotification } from "../../actions/index.actions";
import api from "../../api";
import FullpageLoader from "../../elements/FullpageLoader";
import ErrorUI from "../../elements/ErrorUI";
import moment from "moment";

// Styled components
const Header = styled.div`
  display: flex;
  align-items: end;
  background: #fbfbfb;
  padding: 2px 10px 6px 10px;
  position: fixed;
  width: 100%;
  z-index: 10;
  gap: 16px;
`;
const FilterItem = styled.div`
  width: 40%;
  cursor: pointer;
  label {
    font-size: 14px;
    font-weight: bold;
    color: #555;
  }
  input {
    width: 100%; /* Full width for inputs and dropdowns */
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const FilterBtn = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  button {
    padding: 10px 20px;
    background: #3f6c0f;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      background: #ddffc066;
      color: #0b7420;
      border-radius: 2px;
      border: 1px solid #3f6c1857;
    }
  }
`;
const Container = styled.div`
  position: fixed;
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 65px 10px;
  background: black;
  height: 100%;
  p {
    margin: 0;
    padding: 4px;
    font-size: 18px;
    color: white;
  }
  img {
    min-height: 480px;
    width: 100%;
    object-fit: contain;
    max-height: 480px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  p {
    margin: 8px 0;
    font-size: 16px;
    color: white;
  }
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  border-top: 1px solid #444;
  align-items: center;
  p {
    margin: 0;
    color: white;
    font-size: 18px;
  }
  button {
    padding: 10px 20px;
    background: #3f6c0f;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    &:active {
      background: #eadaf3;
      color: #1b4da3;
    }
    &:disabled {
      background: #888;
      cursor: not-allowed;
    }
  }
`;
class AlteryxWorkReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      reportData: [],
      currentIndex: 0,
      type: null,
      fromDate: "",
      toDate: "",
      nextCursor: null,
      hasMoreData: true,
    };
  }

  componentDidMount = () => {
    const employeeId = localStorage.getItem("employeeId");
    if (!employeeId) {
      window.open("/", "_self");
    } else {
      this.fetchData(employeeId);
    }
  };

  fetchData = async (employeeId, type = null, nextCursor = 0) => {
    const { fromDate, toDate } = this.state;
    this.setState({ isLoading: true, isError: false });
    try {
      const params = { empId: employeeId, nextCursor };
      if (type) params.type = type.type;
      if (fromDate) params.fromDate = moment(fromDate).startOf("day").valueOf();
      if (toDate) params.toDate = moment(toDate).endOf("day").valueOf();

      const response = await api.get("/api/employee/alteryxWorkReport", {
        params,
      });
      const { reports, nextCursor: newCursor } = response.data;
      this.setState((prevState) => ({
        reportData:
          nextCursor === 0 ? reports : [...prevState.reportData, ...reports],
        isLoading: false,
        nextCursor: newCursor,
        hasMoreData: newCursor !== null,
      }));
    } catch (error) {
      console.error("Error fetching data", error);
      showNotification("Error", "Error fetching data", "danger");
      this.setState({ isLoading: false, isError: true });
    }
  };

  handleTypeChange = (selectedOption) => {
    const type = selectedOption ? selectedOption : null;
    this.setState({ type });
  };

  handleDateChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleReset = () => {
    const employeeId = localStorage.getItem("employeeId");
    this.setState(
      {
        type: null,
        fromDate: "",
        toDate: "",
        nextCursor: 0,
        hasMoreData: true,
        reportData: [],
        currentIndex: 0,
      },
      () => {
        this.fetchData(employeeId, null, 0);
      }
    );
  };

  handleNext = () => {
    const { currentIndex, reportData, nextCursor, hasMoreData, type } =
      this.state;
    const employeeId = localStorage.getItem("employeeId");

    if (currentIndex === reportData.length - 1 && hasMoreData) {
      this.fetchData(employeeId, type, nextCursor);
    }
    this.setState((prevState) => ({
      currentIndex: prevState.currentIndex + 1,
    }));
  };

  handlePrev = () => {
    this.setState((prevState) => ({
      currentIndex: prevState.currentIndex - 1,
    }));
  };

  render() {
    const {
      isError,
      isLoading,
      reportData,
      currentIndex,
      type,
      fromDate,
      toDate,
    } = this.state;
    const currentReport = reportData[currentIndex];
    const employeeId = localStorage.getItem("employeeId");
    return (
      <div>
        {isError && <ErrorUI retry={() => this.fetchData(employeeId, type)} />}
        {isLoading && (
          <FullpageLoader
            isOpen={isLoading}
            message="Loading alteryx data... please wait"
          />
        )}
        {!isLoading && !isError && (
          <>
            <Header>
              <FilterItem>
                <label>Select Report Type</label>
                <AltryexTypeSelect
                  onChange={this.handleTypeChange}
                  value={type}
                  isLoading={isLoading}
                  placeholder="Select type"
                />
              </FilterItem>
              <FilterItem>
                <label>Select From Date</label>
                <input
                  type="date"
                  name="fromDate"
                  value={fromDate}
                  onChange={this.handleDateChange}
                />
              </FilterItem>
              <FilterItem>
                <label>Select To Date</label>
                <input
                  type="date"
                  name="toDate"
                  value={toDate}
                  onChange={this.handleDateChange}
                  disabled={!fromDate} // Disable if fromDate is not entered
                  min={fromDate} // Ensure toDate is greater than or equal to fromDate
                />
              </FilterItem>
              <FilterBtn>
                <button onClick={() => this.fetchData(employeeId, type)}>
                  Apply Filter
                </button>
                <button onClick={this.handleReset}>Reset Filter</button>
              </FilterBtn>
            </Header>
            <Container>
              {reportData.length > 0 ? (
                <React.Fragment>
                  <TextContainer>
                    <p>
                      Report Type: {currentReport && currentReport.ReportType}
                    </p>
                    <p>
                      Report Date:{" "}
                      {currentReport &&
                        moment(currentReport.CreatedAt).format("DD/MM/YYYY")}
                    </p>
                  </TextContainer>
                  {currentReport && currentReport.MediaType === "image" && (
                    <img src={currentReport.MediaUrl} alt="Report_Media" />
                  )}
                </React.Fragment>
              ) : (
                <p
                  style={{
                    minHeight: "520px",
                    transform: "translateY(50%)",
                  }}
                >
                  No reports available.
                </p>
              )}
            </Container>
            <Footer>
              <button
                onClick={this.handlePrev}
                disabled={currentIndex === 0 || reportData.length == 0}
              >
                Previous
              </button>
              {reportData.length ? (
                <p>
                  {currentIndex + 1}/{reportData.length}
                </p>
              ) : null}
              <button
                onClick={this.handleNext}
                disabled={
                  (currentIndex === reportData.length - 1 &&
                    !this.state.hasMoreData) ||
                  reportData.length == 0
                }
              >
                Next
              </button>
            </Footer>
          </>
        )}
      </div>
    );
  }
}
export default AlteryxWorkReport;