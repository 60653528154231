// NotificationForm.js
import React, { useState, useEffect } from "react";
import "./NotificationForm.css";
import api from "../../api";
import NotificationConfirmModal from "./NotificationConfirmationModal";
import { showNotification } from "../../actions/index.actions";

function NotificationForm() {
  const [queries, setQueries] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState("");
  const [selectedComment, setSelectedComment] = useState("");
  const [additionalText, setAdditionalText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const employeeId = localStorage.getItem("employeeId");

  const fetchDropdownData = async () => {
    try {
      const response = await api.get("/api/employee/notification-dropdowns", {
        params: { employeeId },
        headers: { "Cache-Control": "no-cache" },
      });
      setQueries(response.data.queries);
      setComments(response.data.comments);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      showNotification(
        "Error",
        "Failed to load dropdown data. Please refresh the page.",
        "danger"
      );
    }
  };

  useEffect(() => {
    fetchDropdownData(employeeId);
  }, []);

  const getSelectedCommentText = () => {
    const comment = comments.find(
      (c) => c.OptionID.toString() === selectedComment
    );
    return comment ? comment.OptionText : "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError("");

    try {
      // First get the count of users
      const countResponse = await api.get("/api/employee/notification-count", {
        params: { queryId: selectedQuery },
      });

      setUserCount(countResponse.data.count);
      setShowConfirmModal(true);
    } catch (error) {
      console.error("Error getting user count:", error);
      setSubmitError(
        "Error checking notification recipients. Please try again."
      );
      showNotification(
        "Error",
        "Failed to get recipient count. Please try again.",
        "danger"
      );
    }
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      const response = await api.post(
        "/api/employee/create-notification",
        {
          selectedQuery,
          selectedComment,
          additionalText,
        },
        {
          params: { employeeId },
          headers: { "Cache-Control": "no-cache" },
        }
      );

      showNotification(
        "Success",
        "Notification created and sent successfully!",
        "success"
      );

      // Reset form on success
      setSelectedQuery("");
      setSelectedComment("");
      setAdditionalText("");
      setShowConfirmModal(false);
    } catch (error) {
      console.error("Error submitting notification:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Error creating notification. Please try again.";
      setSubmitError(errorMessage);

      // Show error notification
      showNotification("Error", errorMessage, "danger");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="notification-form">
      <form onSubmit={handleSubmit}>
        {submitError && <div className="error-message">{submitError}</div>}

        <div className="form-group">
          <label htmlFor="query">Select Query:</label>
          <select
            id="query"
            value={selectedQuery}
            onChange={(e) => setSelectedQuery(e.target.value)}
            required
            disabled={isSubmitting}
          >
            <option value="">Select a query</option>
            {queries.map((query) => (
              <option key={query.QueryID} value={query.QueryID}>
                {query.QueryName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="comment">Select Comment:</label>
          <select
            id="comment"
            value={selectedComment}
            onChange={(e) => setSelectedComment(e.target.value)}
            required
            disabled={isSubmitting}
          >
            <option value="">Select a comment</option>
            {comments.map((comment) => (
              <option key={comment.OptionID} value={comment.OptionID}>
                {comment.OptionText}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="additionalText">Additional Comments:</label>
          <textarea
            id="additionalText"
            value={additionalText}
            onChange={(e) => setAdditionalText(e.target.value)}
            rows="4"
            placeholder="Enter any additional comments..."
            disabled={isSubmitting}
          />
        </div>

        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
      <NotificationConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        userCount={userCount}
        selectedComment={getSelectedCommentText()}
        additionalText={additionalText}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}

export default NotificationForm;
