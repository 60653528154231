import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LoadingState from "./LoadingState";
import ErrorState from "./ErrorState";
import NoDataState from "./NoDataState";
import EnhancedAudioPlayer from "./AudioPlayer";
import api from "../../api";
import ReReviewModal from "./ReReviewModal";
import { showNotification } from "../../actions/index.actions";

const translations = {
  en: {
    taskContent: "Task Content",
    taskCategory: "Task Category",
    appLanguage: "App Language",
    voiceLanguage: "Voice Language",
    guidelines: "Guidelines",
    taskDetails: "Task Details",
    taskDescription: "Task Description",
    language: "Language",
    assignedOn: "Assigned On",
    taskType: "Task Type",
    completedOn: "Completed on",
    try: "Try",
    taskerDetails: "Tasker Details",
    avgRating: "Avg Rating",
    gender: "Gender",
    age: "Age",
    location: "Location",
    profession: "Profession",
    highestEducation: "Highest Education",
    device: "Device",
    fos: "FOS",
    rating: "Rating",
    content: "Content",
    voiceClarity: "Voice/Clarity",
    audibility: "Audibility",
    backgroundNoise: "Background noise",
    transcript: "Transcript",
    comments: "Comments",
    originalReview: "Original Review",
    profiles: "Profiles",
    previous: "Previous",
    reject: "Rejected",
    postpone: "Postponed",
    transfer: "Transferred",
    accept: "Accepted",
    next: "Next",
  },
};

const getTranslation = (lang, key) => translations[lang][key] || key;

const AdminReviewDashboard = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState("en");
  const [dashboardData, setDashboardData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextCursor, setNextCursor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [playbackData, setPlaybackData] = useState(null);
  const [isReReviewModalOpen, setIsReReviewModalOpen] = useState(false);
  const [counts, setCounts] = useState({
    pendingCount: 0,
    completedTodayCount: 0,
  });

  const handlePlaybackDataChange = useCallback((data) => {
    setPlaybackData(data);
  }, []);

  const employeeId = localStorage.getItem("employeeId");
  const t = (key) => getTranslation(language, key);

  const fetchDashboardData = useCallback(
    async (cursor = null) => {
      if (!hasMore) return null;

      try {
        setLoading(true);
        setError(null);

        const response = await api.get("/api/employee/admin-review-details", {
          params: { cursor, limit: 10, employeeId },
          headers: { "Cache-Control": "no-cache" },
        });

        if (
          response.data.message === "No data found for the specified criteria."
        ) {
          if (!cursor) setDashboardData([]);
          setHasMore(false);
          return null;
        }

        const { data, nextCursor } = response.data;

        if (Array.isArray(data) && data.length > 0) {
          const formattedData = data.map((item) => ({
            ...item,
            taskDetails: {
              ...item.taskDetails,
              assignedOn: new Date(
                item.taskDetails.assignedOn
              ).toLocaleDateString("en-GB"),
              completedOn: item.taskDetails.completedOn
                ? new Date(item.taskDetails.completedOn).toLocaleDateString(
                    "en-GB"
                  )
                : null,
            },
          }));

          setDashboardData((prevData) => {
            const newData = formattedData.filter(
              (item) =>
                !prevData.some(
                  (prevItem) => prevItem.taskResultId === item.taskResultId
                )
            );
            return [...prevData, ...newData];
          });

          setNextCursor(nextCursor);
          setHasMore(!!nextCursor);
          return true;
        }

        return null;
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError(`Failed to fetch dashboard data: ${err.message}`);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [hasMore, employeeId]
  );

  const handleNavigation = useCallback(
    async (direction) => {
      if (direction === "next") {
        if (currentIndex < dashboardData.length - 1) {
          setCurrentIndex(currentIndex + 1);
        } else if (hasMore) {
          const fetchSuccessful = await fetchDashboardData(nextCursor);
          if (fetchSuccessful) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
          } else {
            setHasMore(false);
          }
        }
      } else if (direction === "previous" && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    },
    [
      currentIndex,
      dashboardData.length,
      hasMore,
      nextCursor,
      fetchDashboardData,
    ]
  );

  const handleReReview = async (reReviewData) => {
    try {
      console.log("Submitting re-review with data:", reReviewData);

      const response = await api.post(
        `/api/employee/submit-re-review?employeeId=${employeeId}`,
        {
          reReviewId: reReviewData.reReviewId,
          selectedCommentOption: parseInt(reReviewData.selectedCommentOption),
          additionalComment: reReviewData.additionalComment,
          reReviewStatus: reReviewData.reReviewStatus,
        }
      );

      if (response.data.success) {
        showNotification(
          "Success",
          "Re-review submitted successfully",
          "success"
        );

        fetchCounts();

        // Remove the reviewed task from the dashboard
        setDashboardData((prevData) =>
          prevData.filter((item) => item.reReviewId !== reReviewData.reReviewId)
        );

        // If we removed the last item of the current page, load more
        if (currentIndex === dashboardData.length - 1 && hasMore) {
          await fetchDashboardData(nextCursor);
        }
        // If we're at the last item, move to previous
        if (currentIndex === dashboardData.length - 1) {
          setCurrentIndex((prev) => Math.max(0, prev - 1));
        }
      } else {
        throw new Error(response.data.message || "Failed to submit re-review");
      }
    } catch (error) {
      console.error("Error submitting re-review:", error);
      showNotification(
        "Error",
        error.response?.data?.message || "Failed to submit re-review",
        "danger"
      );
    }
  };

  const fetchCounts = useCallback(async () => {
    try {
      const response = await api.get("/api/employee/re-review-counts", {
        params: { employeeId },
        headers: { "Cache-Control": "no-cache" },
      });
      setCounts(response.data);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  }, [employeeId]);

  // Update useEffect
  useEffect(() => {
    if (!employeeId) {
      navigate("/sign-in");
      return;
    }
    fetchDashboardData();
    fetchCounts();
  }, [fetchDashboardData, fetchCounts, employeeId, navigate]);

  if (loading && dashboardData.length === 0) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (dashboardData.length === 0) return <NoDataState />;

  const currentData = dashboardData[currentIndex];
  if (!currentData) return <NoDataState />;

  const {
    taskContent,
    taskDetails,
    taskerDetails,
    originalReview,
    originalReviewStatus,
  } = currentData;
  const isLastRecord = currentIndex === dashboardData.length - 1 && !hasMore;

  const getStatusStyles = (status, targetStatus) => ({
    ...styles.button,
    ...(status === targetStatus
      ? styles.highlightedButton
      : styles.disabledButton),
  });

  return (
    <div style={styles.taskerDashboard}>
      <div style={styles.mainContent}>
        {/* Task Content Section */}
        <div style={styles.taskContent}>
          <div style={styles.taskContentHeader}>
            <h2 style={styles.heading}>{t("taskContent")}</h2>
            <div style={styles.headerCounts}>
              <div style={styles.countItem}>
                <div style={styles.countValue}>{counts.pendingCount}</div>
                <div style={styles.countLabel}>Pending</div>
              </div>
              <div style={styles.countDivider}></div>
              <div style={styles.countItem}>
                <div style={styles.countValue}>
                  {counts.completedTodayCount}
                </div>
                <div style={styles.countLabel}>Today</div>
              </div>
            </div>
          </div>
          <div style={styles.audioPlayerContainer}>
            <EnhancedAudioPlayer
              src={taskContent.taskUrl}
              style={styles.audioPlayer}
              readOnly={true}
              onPlaybackDataChange={handlePlaybackDataChange}
            />
          </div>
        </div>

        {/* Details Column */}
        <div style={styles.detailsColumn}>
          {/* Task Details */}
          <div style={styles.taskDetails}>
            <h2 style={styles.heading}>{t("taskDetails")}</h2>
            <div style={styles.taskDescription}>
              <h3 style={styles.subheading}>{t("taskDescription")}</h3>
              <p style={styles.descriptionText}>
                {taskDetails.taskDescription}
              </p>
            </div>
            <div style={styles.taskInfo}>
              <div>
                <span style={styles.label}>{t("language")}:</span>
                <span style={styles.labelValue}>{taskDetails.language}</span>
              </div>
              <div>
                <span style={styles.label}>{t("assignedOn")}:</span>
                <span style={styles.labelValue}>{taskDetails.assignedOn}</span>
              </div>
              <div>
                <span style={styles.label}>{t("taskCategory")}:</span>
                <span style={styles.labelValue}>
                  {taskDetails.taskCategory}
                </span>
              </div>
              <div>
                <span style={styles.label}>{t("completedOn")}:</span>
                <span style={styles.labelValue}>
                  {taskDetails.completedOn || "Not completed"}
                </span>
              </div>
              <div>
                <span style={styles.label}>{t("taskType")}:</span>
                <span
                  style={{ ...styles.labelValue, textTransform: "capitalize" }}
                >
                  {taskDetails.taskType}
                </span>
              </div>
            </div>
          </div>

          {/* Tasker Details */}
          <div style={styles.taskerDetails}>
            <h2 style={styles.heading}>{t("taskerDetails")}</h2>
            <div style={styles.taskerInfo}>
              <div style={styles.column}>
                <div style={styles.field}>
                  <span style={styles.label}>{t("avgRating")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.avgRating}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("age")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.age}</span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("gender")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.gender}</span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("location")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.location}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("fos")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.fos}</span>
                </div>
              </div>
              <div style={styles.column}>
                <div style={styles.field}>
                  <span style={styles.label}>{t("appLanguage")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.appLanguage}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("voiceLanguage")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.voiceLanguages}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("profession")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.profession}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("highestEducation")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.highestEducation}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("device")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.device}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Original Review Section */}
      <div style={styles.secondaryContent}>
        <div style={styles.reviewSection}>
          <h2 style={styles.heading}>{t("originalReview")}</h2>
          <div style={styles.ratingsGrid}>
            {originalReview.ratings.map((rating) => (
              <div key={rating.CriteriaID} style={styles.ratingItem}>
                <span style={styles.label}>{rating.CriteriaName}:</span>
                <span style={styles.labelValue}>{rating.Rating}</span>
              </div>
            ))}
          </div>
        </div>
        <div style={styles.transcriptSection}>
          <h2 style={styles.heading}>{t("transcript")}</h2>
          <div style={styles.transcriptBox}>{originalReview.transcript}</div>
        </div>
      </div>

      <div style={styles.tertiaryContent}>
        <div style={styles.commentSection}>
          <h2 style={styles.heading}>{t("comments")}</h2>
          <div style={styles.commentBox}>
            <div style={styles.field}>
              <span style={styles.label}>Selected Comment:</span>
              <span style={styles.labelValue}>
                {originalReview.comment.OptionText}
              </span>
            </div>
            <div style={styles.field}>
              <span style={styles.label}>Additional Comment:</span>
              <span style={styles.labelValue}>
                {originalReview.comment.AdditionalComment}
              </span>
            </div>
          </div>
        </div>
        <div style={styles.profileSection}>
          <h2 style={styles.heading}>{t("profiles")}</h2>
          <div style={styles.profilesBox}>
            {originalReview.selectedProfiles.map((profile, index) => (
              <div key={index} style={styles.field}>
                <span style={styles.labelValue}>{profile.Description}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Navigation */}
      <div style={styles.navigation}>
        <button
          style={{
            ...styles.button,
            ...(currentIndex === 0 ? styles.disabledButton : {}),
          }}
          onClick={() => handleNavigation("previous")}
          disabled={currentIndex === 0}
        >
          {t("previous")}
        </button>

        <div style={styles.actionsContainer}>
          <div style={styles.actionButtons}>
            <button style={getStatusStyles(originalReviewStatus, 6)} disabled>
              {t("reject")}
            </button>
            <button style={getStatusStyles(originalReviewStatus, 2)} disabled>
              {t("postpone")}
            </button>
            <button style={getStatusStyles(originalReviewStatus, 4)} disabled>
              {t("transfer")}
            </button>
            <button style={getStatusStyles(originalReviewStatus, 1)} disabled>
              {t("accept")}
            </button>
          </div>
          <button
            style={styles.reReviewButton}
            onClick={() => setIsReReviewModalOpen(true)}
          >
            Re-Review
          </button>
        </div>

        <button
          style={{
            ...styles.button,
            ...(isLastRecord ? styles.disabledButton : {}),
          }}
          onClick={() => handleNavigation("next")}
          disabled={isLastRecord}
        >
          {t("next")}
        </button>
      </div>

      <ReReviewModal
        isOpen={isReReviewModalOpen}
        onClose={() => setIsReReviewModalOpen(false)}
        onSubmit={handleReReview}
        taskResultId={currentData?.reReviewId}
      />
    </div>
  );
};

// Using existing styles plus a few additions
const styles = {
  // All existing styles
  guidelinesButton: {
    padding: "0.25rem 0.5rem",
    border: "none",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: "600",
    cursor: "pointer",
    backgroundColor: "#4299e1",
    color: "white",
    transition: "background-color 0.3s",
  },
  detailsColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  taskDetails: {
    border: "1px solid #e2e8f0",
    padding: "0.5rem",
    flex: "1 1 50%",
    overflowY: "none",
  },
  descriptionText: {
    marginTop: "0.25rem",
    fontSize: "0.875rem",
  },
  comments: {
    width: "30%",
  },
  commentInputs: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  select: {
    padding: "0.25rem",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    fontSize: "0.875rem",
    width: "100%",
  },
  textarea: {
    padding: "0.25rem",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    fontSize: "0.875rem",
    minHeight: "4rem",
    resize: "vertical",
    width: "100%",
  },
  rejectButton: {
    backgroundColor: "#e53e3e",
  },
  postponeButton: {
    backgroundColor: "#ed8936",
  },
  transferButton: {
    backgroundColor: "#38a169",
  },
  acceptButton: {
    backgroundColor: "#38a169",
  },
  actionButtons: {
    display: "flex",
    gap: "0.25rem",
  },
  heading: {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginBottom: "0.15rem",
  },
  subheading: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  taskInfo: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    fontSize: "0.875rem",
    marginTop: "0.25rem",
  },
  transcriptDescription: {
    fontSize: "0.875rem",
    marginBottom: "0.5rem",
  },
  transcriptBox: {
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    padding: "0.5rem",
    fontSize: "0.875rem",
    width: "90%",
    height: "calc(100% - 4rem)",
    resize: "none",
    overflowY: "auto",
  },
  fullWidthInfo: {
    gridColumn: "1 / -1",
    display: "flex",
    alignItems: "flex-start",
  },
  leftColumn: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  },
  rightColumn: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  },
  button: {
    padding: "0.35rem 0.75rem", // Reduced padding to make buttons smaller
    border: "none",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: "600",
    cursor: "pointer",
    transition: "background-color 0.3s, transform 0.1s",
    outline: "none",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#3182ce",
    color: "white",
    height: "32px", // Added fixed height for consistency
    display: "flex",
    alignItems: "center", // Center text vertically
    justifyContent: "center", // Center text horizontally
  },
  disabledButton: {
    backgroundColor: "#a0aec0",
    cursor: "not-allowed",
    opacity: 0.6,
    boxShadow: "none",
  },
  locationContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
  },
  locationText: {
    margin: 0,
    marginLeft: "0.5rem",
    fontSize: "0.875rem",
    color: "#2c5282",
  },
  taskContent: {
    border: "1px solid #e2e8f0",
    padding: "1rem",
    backgroundColor: "#f7fafc",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    height: "90%",
  },

  audioPlayerContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#edf2f7",
    borderRadius: "8px",
    padding: "1rem",
  },
  audioPlayer: {
    width: "100%",
    maxWidth: "500px",
  },
  taskerDetails: {
    border: "1px solid #e2e8f0",
    padding: "0.5rem",
    flex: "1 1 50%",
    overflowY: "auto",
  },
  taskerInfo: {
    display: "flex",
    gap: "1rem",
    fontSize: "0.875rem",
  },
  column: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  field: {
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    fontWeight: "bold",
    marginRight: "0.5rem",
    minWidth: "150px",
    flexShrink: 0,
  },
  labelValue: {
    backgroundColor: "rgba(66, 153, 225, 0.1)",
    padding: "0.25rem 0.5rem",
    borderRadius: "6px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    flex: 1,
  },
  secondaryContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    marginTop: "0rem",
    minHeight: "80%",
  },
  tertiaryContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    marginTop: "1rem",
    minHeight: "80%",
  },
  taskerDashboard: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "white",
    color: "black",
    padding: "0.5rem",
    boxSizing: "border-box",
    gap: "0.25rem",
  },
  mainContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    minHeight: "40vh",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", // Added this to center buttons vertically
    marginTop: "auto",
    paddingTop: "1rem",
    gap: "1rem",
  },

  // New styles for admin review specific elements
  highlightedButton: {
    cursor: "not-allowed",
    opacity: 1,
    boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.5)",
  },
  reviewSection: {
    border: "1px solid #e2e8f0",
    borderRadius: "8px",
    padding: "1rem",
    backgroundColor: "#f8fafc",
  },
  ratingsGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    padding: "0.5rem",
  },
  ratingItem: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    padding: "0.5rem",
    backgroundColor: "white",
    borderRadius: "6px",
    border: "1px solid #e2e8f0",
  },
  commentBox: {
    border: "1px solid #e2e8f0",
    borderRadius: "8px",
    padding: "1rem",
    backgroundColor: "#f8fafc",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  profilesBox: {
    border: "1px solid #e2e8f0",
    borderRadius: "8px",
    padding: "1rem",
    backgroundColor: "#f8fafc",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  originalStatusButton: {
    width: "120px",
    textAlign: "center",
    fontWeight: "bold",
  },
  statusAccepted: {
    backgroundColor: "#48BB78",
    opacity: 1,
  },
  statusRejected: {
    backgroundColor: "#F56565",
    opacity: 1,
  },
  statusPostponed: {
    backgroundColor: "#ED8936",
    opacity: 1,
  },
  statusTransferred: {
    backgroundColor: "#4299E1",
    opacity: 1,
  },

  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
    flex: "0 1 auto", // Allow container to shrink
  },

  // Add these to your existing styles object

  pendingCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f0f9ff",
    border: "1px solid #93c5fd",
    borderRadius: "4px",
    padding: "0.5rem 1rem",
    flex: "1",
    gap: "0.5rem",
  },
  pendingLabel: {
    color: "#1e40af",
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  pendingNumber: {
    color: "#1e40af",
    fontSize: "1.125rem",
    fontWeight: "700",
  },

  reReviewContainer: {
    display: "flex",
    gap: "0.75rem",
    width: "100%",
  },

  countsBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f8fafc",
    border: "1px solid #e2e8f0",
    borderRadius: "6px",
    padding: "0.5rem 0.75rem",
    flex: "1",
    gap: "0.5rem",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  },

  reReviewButton: {
    padding: "0.75rem 2rem",
    fontSize: "1rem",
    fontWeight: "600",
    backgroundColor: "#4299e1",
    color: "white",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    transition: "all 0.2s",
    boxShadow: "0 2px 4px rgba(66, 153, 225, 0.3)",
    width: "100%",
    marginTop: "0.5rem",
    "&:hover": {
      backgroundColor: "#3182ce",
      transform: "translateY(-1px)",
      boxShadow: "0 4px 6px rgba(66, 153, 225, 0.4)",
    },
  },

  taskContentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
    padding: "0.5rem",
    backgroundColor: "#f8fafc",
    borderRadius: "6px",
    border: "1px solid #e2e8f0",
  },

  headerCounts: {
    display: "flex",
    alignItems: "center",
    gap: "0.75rem", // Reduced gap
    padding: "0.25rem 0.75rem", // Reduced padding
    backgroundColor: "white",
    borderRadius: "6px",
    border: "1px solid #e2e8f0",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  },

  countItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 0.25rem", // Reduced padding
  },

  countValue: {
    fontSize: "1rem", // Reduced from 1.25rem
    fontWeight: "600", // Reduced from 700
    color: "#2563eb",
    lineHeight: "1",
  },

  countLabel: {
    fontSize: "0.675rem", // Reduced from 0.75rem
    color: "#64748b",
    fontWeight: "500",
    textTransform: "uppercase",
    letterSpacing: "0.025em",
  },

  countDivider: {
    width: "1px",
    height: "1.5rem", // Reduced from 2rem
    backgroundColor: "#e2e8f0",
  },
};

export default AdminReviewDashboard;
