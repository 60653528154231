import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import allMenus from "../components/EmployeeComponents/menu"; // Import predefined menus
import AuthLayout from "./Layout/AuthLayout";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  // Fetch menu access for the employee from localStorage
  const menuAccess = JSON.parse(localStorage.getItem("menuAccess") || "[]");

  // Extract the current route path from the location
  const currentPath = location.pathname;

  // Fetch employeeId from localStorage for checking if the user is logged in
  const employeeId = localStorage.getItem("employeeId");

  // Check if the user is logged in, if not redirect to sign-in page
  if (!employeeId) {
    return <Navigate to="/sign-in" />;
  }

  // If the user is on the homepage, grant access automatically (or if you want to display a dashboard)
  if (currentPath === "/") {
    return <AuthLayout>{children}</AuthLayout>;
  }

  // Check if the user has access to the current path (other than homepage)
  const isAccessGranted = menuAccess.some((access) => {
    return allMenus.some((menu) => {
      return menu.subMenus.some((subMenu) => {
        return subMenu.path === currentPath && access.MenuName === subMenu.id;
      });
    });
  });

  // If access is granted, render the children (protected content)
  if (isAccessGranted) {
    return <AuthLayout>{children}</AuthLayout>;
  }

  // If access is not granted, redirect to the homepage
  return <Navigate to="/" />;
};

export default ProtectedRoute;
