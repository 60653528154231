import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getURL } from "../../commons/commons";
import PropTypes from "prop-types";
import api from '../../api';
/**
 * @author Suman Kumar. 30 December 2024
 */
function AltryexTypeSelect(props) {
    const [isLoading, setLoader] = useState(true);
    const [options, setOptions] = useState([]);
    const employeeId = localStorage.getItem("employeeId");
    useEffect(() => {
        // Define an async function inside the useEffect
        const fetchAltryexType = async () => {
            try {
                const url = getURL(`/api/employee/alteryxWorkReport/type?empId=${employeeId}`);
                const response = await api.get(url, {
                    headers: { "Cache-Control": "no-cache" },
                });
                const alteryxWorkReportTypeList = response.data.alteryxWorkReportTypeList;
                setOptions(alteryxWorkReportTypeList);
            } catch (error) {
                console.error("Error fetching alteryx lists:", error);
            } finally {
                setLoader(false);
            }
        };
        fetchAltryexType();
    }, []);
    return (
        <Select
            options={options}
            isLoading={isLoading}
            onChange={props.onChange}
            value={props.value}
            isClearable
            placeholder="Select Type"
            getOptionLabel={(option) => option.type} // Use the custom label format
            getOptionValue={(option) => option.id} // Use the custom value format
            {...props.options}
        />
    );
}

AltryexTypeSelect.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.object, // Prop types for additional options if needed
};

export default AltryexTypeSelect;
