import React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LoadingState from "./LoadingState";
import ErrorState from "./ErrorState";
import NoDataState from "./NoDataState";
import EnhancedAudioPlayer from "./AudioPlayer";
import RatingSection from "./RatingSection";
import TranscriptSection from "./TranscriptSection";
import CommentsSection from "./CommentSection";
import TaskerProfilesSelect from "./TaskerProfilesSelect";
import { showNotification } from "../../actions/index.actions";

import ValidationErrorModal from "../TaskDashboardComponents/ValidationErrorModal";
import PostponeModal from "./PostPoneModal";
import ConfirmationModal from "./ConfirmationModal";
import api from "../../api";

const translations = {
  en: {
    taskContent: "Task Content",
    guidelines: "Guidelines",
    taskDetails: "Task Details",
    taskDescription: "Task Description",
    taskCategory: "Task Category",
    appLanguage: "App Language",
    voiceLanguage: "Voice Language",
    sampleDescription:
      "This is a sample task description. It provides a brief overview of what needs to be done.",
    language: "Language",
    assignedOn: "Assigned On",
    taskType: "Task Type",
    completedOn: "Completed on",
    try: "Try",
    taskerDetails: "Tasker Details",
    avgRating: "Avg Rating",
    gender: "Gender",
    age: "Age",
    location: "Location",
    profession: "Profession",
    highestEducation: "Highest Education",
    device: "Device",
    fos: "FOS",
    rating: "Rating",
    content: "Content",
    voiceClarity: "Voice/Clarity",
    audibility: "Audibility",
    backgroundNoise: "Background noise",
    transcript: "Transcript",
    writeTranscription: "Write the transcription here:",
    enterTranscript: "Enter the transcript text here...",
    comments: "Comments",
    selectComment: "Select a comment",
    audio: "Audio",
    clarity: "Clarity",
    background: "Background",
    typeAdditionalComments: "Type additional comments here...",
    previous: "Previous",
    reject: "Reject",
    postpone: "Postpone",
    transfer: "Transfer",
    accept: "Accept",
    next: "Next",
  },
  // Add more languages here
};

const getTranslation = (lang, key) => {
  return translations[lang][key] || key;
};

const TaskerDashboard = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState("en");

  const [dashboardData, setDashboardData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextCursor, setNextCursor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [totalFetched, setTotalFetched] = useState(0);

  const [ratings, setRatings] = useState({});
  const [transcript, setTranscript] = useState("");
  const [selectedComment, setSelectedComment] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [playbackData, setPlaybackData] = useState(null);

  const [validationErrors, setValidationErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const [ratingParameters, setRatingParameters] = useState([]);

  const [isPostponeModalOpen, setIsPostponeModalOpen] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [isPostponing, setIsPostponing] = useState(false);
  const [employeeIdError, setEmployeeIdError] = useState(false);

  const [counts, setCounts] = useState({
    pendingCount: 0,
    completedTodayCount: 0,
  });

  // Add these new state variables in TaskerDashboard
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    type: null, // 'accept' | 'reject' | 'transfer' | 'postpone'
    title: "",
    message: "",
    onConfirm: null,
    isLoading: false,
  });

  // confirmation modal components
  const openConfirmationModal = (type, onConfirm) => {
    const modalConfig = {
      accept: {
        title: "Confirm Accept",
        message: "Are you sure you want to accept this task?",
      },
      reject: {
        title: "Confirm Reject",
        message: "Are you sure you want to reject this task?",
      },
      transfer: {
        title: "Confirm Transfer",
        message: "Are you sure you want to transfer this task?",
      },
      postpone: {
        title: "Confirm Postpone",
        message: (days) =>
          `Are you sure you want to postpone this task by ${days} days?`,
      },
    };

    setConfirmationModal({
      isOpen: true,
      type,
      title: modalConfig[type].title,
      message:
        typeof modalConfig[type].message === "function"
          ? modalConfig[type].message()
          : modalConfig[type].message,
      onConfirm,
      isLoading: false,
    });
  };

  const closeConfirmationModal = () => {
    setConfirmationModal({
      isOpen: false,
      type: null,
      title: "",
      message: "",
      onConfirm: null,
      isLoading: false,
    });
  };

  const ratingSectionRef = useRef(null);

  const employeeId = localStorage.getItem("employeeId");

  const handlePlaybackDataChange = useCallback((data) => {
    setPlaybackData(data);
  }, []);

  const validateForm = useCallback(() => {
    const errors = {};

    // Check if this is an invalid audio case
    const isInvalidAudio =
      playbackData &&
      (playbackData.totalDuration === Infinity ||
        playbackData.totalDuration === null ||
        playbackData.totalDuration < 1);

    // Only check audio playback if duration is 1 second or more, not null, and not Infinity
    if (
      playbackData &&
      playbackData.totalDuration !== null &&
      playbackData.totalDuration !== Infinity &&
      playbackData.totalDuration >= 1
    ) {
      console.log("Audio > 1s, checking if listened");
      if (!playbackData || playbackData.maxListenedDuration === 0) {
        errors.audio =
          "You must listen to the audio before submitting or rejecting";
      }
    } else {
      console.log("Short/unplayable audio or no playback data");
    }

    // Only validate ratings and profiles if this is NOT an invalid audio case
    if (!isInvalidAudio) {
      // Check ratings against fetched parameters
      if (ratingParameters.length > 0) {
        const missingRatings = ratingParameters.filter((param) => {
          const rating = ratings[param.CriteriaID];
          return !rating || rating.value === 0;
        });

        if (missingRatings.length > 0) {
          errors.ratings = `Please provide ratings for: ${missingRatings
            .map((param) => param.CriteriaName)
            .join(", ")}`;
        }
      }

      // Check profile selection
      if (selectedProfiles.length === 0) {
        errors.profiles = "Please select at least one profile";
      }
    }

    // Comment selection is always required
    if (!selectedComment) {
      errors.comments = "Please select a comment option";
    }

    setValidationErrors(errors);
    const isValid = Object.keys(errors).length === 0;
    setIsFormValid(isValid);

    return isValid;
  }, [
    ratings,
    selectedComment,
    selectedProfiles,
    ratingParameters,
    playbackData,
  ]);

  // Add new function specifically for reject button
  const canReject = useCallback(() => {
    // Allow reject if audio is very short, unplayable (null duration) or has Infinity duration
    if (
      playbackData &&
      (playbackData.totalDuration < 1 ||
        playbackData.totalDuration === null ||
        playbackData.totalDuration === Infinity)
    ) {
      console.log("Short/unplayable/invalid audio detected, allowing reject");
      return true;
    }
    // Otherwise require listening
    const hasListened = playbackData && playbackData.maxListenedDuration > 0;
    console.log("Regular audio, requires listening:", hasListened);
    return hasListened;
  }, [playbackData]);

  // Keep original function for accept button
  const hasListenedToAudio = useCallback(() => {
    return playbackData && playbackData.maxListenedDuration > 0;
  }, [playbackData]);

  useEffect(() => {
    validateForm();
  }, [
    ratings,
    selectedComment,
    selectedProfiles,
    ratingParameters,
    validateForm,
  ]);

  useEffect(() => {
    const fetchRatingParameters = async () => {
      try {
        const response = await api.get("/api/employee/rating-parameters", {
          params: { contentType: "audio" },
          headers: { "Cache-Control": "no-cache" },
        });
        setRatingParameters(response.data);
      } catch (error) {
        // Keep console.error for debugging but add user notification
        console.error("Error fetching rating parameters:", error);
        showNotification(
          "Error",
          "Failed to fetch rating parameters. Please try refreshing the page.",
          "danger"
        );
      }
    };

    fetchRatingParameters();
  }, []);

  const t = (key) => getTranslation(language, key);

  const fetchCounts = useCallback(async () => {
    try {
      const response = await api.get("/api/employee/task-counts", {
        params: { employeeId },
        headers: { "Cache-Control": "no-cache" },
      });
      setCounts(response.data);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  }, [employeeId]);

  const fetchDashboardData = useCallback(
    async (cursor = null) => {
      if (!hasMore) return null; // Return null if there's no more data

      try {
        setLoading(true);
        setError(null);

        const response = await api.get("/api/employee", {
          params: { cursor, limit: 10, employeeId: employeeId },
          headers: { "Cache-Control": "no-cache" },
        });

        if (
          response.data.message === "No data found for the specified criteria."
        ) {
          if (!cursor) {
            setDashboardData([]);
          }
          setHasMore(false);
          return null;
        }

        const { data, nextCursor } = response.data;

        if (Array.isArray(data) && data.length > 0) {
          const formattedData = data.map((item) => ({
            ...item,
            taskDetails: {
              ...item.taskDetails,
              assignedOn: item.taskDetails.assignedOn
                ? new Date(parseInt(item.taskDetails.assignedOn))
                    .toLocaleDateString("en-GB")
                    .split("/")
                    .join("/")
                : null,
              completedOn: item.taskDetails.completedOn
                ? new Date(parseInt(item.taskDetails.completedOn))
                    .toLocaleDateString("en-GB")
                    .split("/")
                    .join("/")
                : null,
            },
          }));

          setDashboardData((prevData) => {
            const newData = formattedData.filter(
              (item) =>
                !prevData.some(
                  (prevItem) => prevItem.taskResultId === item.taskResultId
                )
            );
            return [...prevData, ...newData];
          });

          setNextCursor(nextCursor);
          setHasMore(!!nextCursor);
          setTotalFetched((prev) => prev + data.length);

          return true; // Return true to indicate successful fetch
        }

        return null; // Return null if no data received
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError(`Failed to fetch dashboard data: ${err.message}`);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [hasMore, employeeId]
  );

  const handleSubmit = async () => {
    if (!validateForm()) {
      setIsErrorModalOpen(true);
      return;
    }

    openConfirmationModal("accept", async () => {
      setConfirmationModal((prev) => ({ ...prev, isLoading: true }));
      try {
        const currentData = dashboardData[currentIndex];

        const reviewData = {
          taskResultId: currentData.taskResultId,
          employeeId,
          performerUserId: currentData.taskerDetails.taskerId,
          ratings: Object.values(ratings).map(({ criteriaId, value }) => ({
            criteriaId,
            value,
          })),
          transcript: transcript,
          comment: {
            selectedOption: selectedComment,
            additionalComment: additionalComment,
          },
          selectedProfiles: selectedProfiles.map((profile) => ({
            id: profile.id,
          })),
        };

        const response = await api.post(
          "/api/employee/submit-review",
          reviewData,
          { headers: { "Cache-Control": "no-cache" } }
        );

        if (playbackData) {
          const mediaDurationData = {
            taskResultId: currentData.taskResultId,
            mediaType: "audio",
            totalMediaDuration:
              playbackData.totalDuration === Infinity
                ? 0
                : playbackData.totalDuration || 0,
            maxListenedDuration: playbackData.maxListenedDuration || 0,
            listenedDateTime: Date.now(),
          };

          const mediaDurationResponse = await api.post(
            "/api/employee/store-media-duration",
            mediaDurationData,
            { headers: { "Cache-Control": "no-cache" } }
          );

          if (mediaDurationResponse.status !== 200) {
            throw new Error(
              mediaDurationResponse.data.error ||
                "Failed to store media duration data"
            );
          }
        }

        if (response.status === 200) {
          closeConfirmationModal();
          showNotification(
            "Success",
            "Review submitted successfully",
            "success"
          );
          removeTaskFromState(currentData.taskResultId);
          fetchCounts();
        } else {
          throw new Error(response.data.error || "Failed to submit review");
        }
      } catch (error) {
        console.error("Error submitting review:", error);
        closeConfirmationModal();
        if (error.response) {
          showNotification(
            "Error",
            `Failed to submit review: ${
              error.response.data.error || "Unknown error"
            }`,
            "danger"
          );
        } else {
          showNotification(
            "Error",
            `Failed to submit review: ${error.message}`,
            "danger"
          );
        }
      }
    });
  };

  const handleTransfer = async () => {
    openConfirmationModal("transfer", async () => {
      setConfirmationModal((prev) => ({ ...prev, isLoading: true }));
      try {
        const currentData = dashboardData[currentIndex];
        const response = await api.post(
          "/api/employee/update-task-status",
          {
            taskResultId: currentData.taskResultId,
            newStatus: 4,
            employeeId,
          },
          {
            headers: { "Cache-Control": "no-cache" },
          }
        );

        if (response.status === 200) {
          closeConfirmationModal();
          showNotification(
            "Success",
            "Task transferred successfully",
            "success"
          );
          removeTaskFromState(currentData.taskResultId);
          fetchCounts();
        } else {
          throw new Error(response.data.error || "Failed to request transfer");
        }
      } catch (error) {
        console.error("Error requesting transfer:", error);
        closeConfirmationModal();
        showNotification(
          "Error",
          `Failed to transfer task: ${error.message}`,
          "danger"
        );
      }
    });
  };

  const handlePostpone = async (days) => {
    // Close the postpone days modal first
    setIsPostponeModalOpen(false);

    // Open confirmation modal with days information
    openConfirmationModal("postpone", async () => {
      setConfirmationModal((prev) => ({
        ...prev,
        isLoading: true,
        message: `Are you sure you want to postpone this task by ${days} days?`,
      }));

      try {
        const currentData = dashboardData[currentIndex];
        const response = await api.post(
          "/api/employee/update-task-status",
          {
            taskResultId: currentData.taskResultId,
            newStatus: 2,
            employeeId,
            postponeDays: days,
          },
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );

        if (response.status === 200) {
          closeConfirmationModal();
          showNotification("Success", "Task postponed successfully", "success");
          removeTaskFromState(currentData.taskResultId);
          fetchCounts();
        } else {
          throw new Error(response.data.error || "Failed to postpone task");
        }
      } catch (error) {
        console.error("Error postponing task:", error);
        closeConfirmationModal();
        showNotification(
          "Error",
          `Failed to postpone task: ${error.message}`,
          "danger"
        );
      }
    });
  };

  const handleReject = async () => {
    if (!validateForm()) {
      setIsErrorModalOpen(true);
      return;
    }

    openConfirmationModal("reject", async () => {
      setConfirmationModal((prev) => ({ ...prev, isLoading: true }));
      try {
        const currentData = dashboardData[currentIndex];

        if (!selectedComment) {
          showNotification(
            "Missing Information",
            "Please select a comment before rejecting the task.",
            "warning"
          );
          return;
        }

        // Check if this is an invalid audio case
        const isInvalidAudio =
          playbackData &&
          (playbackData.totalDuration === Infinity ||
            playbackData.totalDuration === null ||
            playbackData.totalDuration < 1);

        const rejectData = {
          taskResultId: currentData.taskResultId,
          employeeId,
          performerUserId: currentData.taskerDetails.taskerId,
          selectedComment: selectedComment,
          additionalComment: additionalComment,
          // Only include ratings and profiles if it's valid audio
          ...(isInvalidAudio
            ? {}
            : {
                ratings: Object.values(ratings).map(
                  ({ criteriaId, value }) => ({
                    criteriaId,
                    value,
                  })
                ),
                selectedProfiles: selectedProfiles.map((profile) => ({
                  id: profile.id,
                })),
              }),
          isInvalidAudio,
        };

        const response = await api.post(
          "/api/employee/reject-task",
          rejectData
        );

        if (response.status === 200) {
          closeConfirmationModal();
          showNotification("Success", "Task rejected successfully", "success");
          removeTaskFromState(currentData.taskResultId);
          fetchCounts();
        } else {
          throw new Error(response.data.error || "Failed to reject task");
        }
      } catch (error) {
        console.error("Error rejecting task:", error);
        closeConfirmationModal();
        showNotification(
          "Error",
          `Failed to reject task: ${
            error.response?.data?.error || error.message
          }`,
          "danger"
        );
      }
    });
  };

  const clearFormStates = useCallback(() => {
    setRatings({}); // Clear ratings in parent
    setTranscript("");
    setSelectedComment("");
    setAdditionalComment("");
    setSelectedProfiles([]);
    setPlaybackData(null);
    if (ratingSectionRef.current) {
      ratingSectionRef.current.resetRatings();
    }
  }, []);

  const removeTaskFromState = useCallback(
    (taskResultId) => {
      setDashboardData((prevData) =>
        prevData.filter((item) => item.taskResultId !== taskResultId)
      );

      // Clear all form states
      clearFormStates();

      // If there are more tasks after removal
      if (dashboardData.length > 1) {
        if (currentIndex >= dashboardData.length - 1) {
          // If we're at the last item, move to the previous one
          setCurrentIndex((prev) => Math.max(0, prev - 1));
        }
        // If we're somewhere in the middle, current index stays the same
        // as the next item will slide into the current position
      } else if (hasMore) {
        // If we've removed the last item and there are more to fetch
        fetchDashboardData(nextCursor);
      }
    },
    [
      currentIndex,
      dashboardData.length,
      hasMore,
      nextCursor,
      fetchDashboardData,
      clearFormStates,
    ]
  );

  useEffect(() => {
    const checkEmployeeId = () => {
      const employeeId = localStorage.getItem("employeeId");

      if (!employeeId) {
        setEmployeeIdError(true);
        setIsErrorModalOpen(true);
        setValidationErrors({
          employeeId: "Employee ID not found. Please log in again.",
        });

        // Disable all the dashboard functionality
        setLoading(false);
        setDashboardData([]);
        setHasMore(false);

        // Show error modal briefly before redirecting
        setTimeout(() => {
          navigate("/sign-in");
        }, 2000);

        return false;
      }
      return true;
    };

    const isValid = checkEmployeeId();
    if (isValid) {
      fetchDashboardData();
      fetchCounts();
    }
  }, [fetchDashboardData, navigate, fetchCounts]);

  const handleNavigation = useCallback(
    async (direction) => {
      if (direction === "next") {
        if (currentIndex < dashboardData.length - 1) {
          // If we have data in memory, just move to next index
          setCurrentIndex(currentIndex + 1);
        } else if (hasMore) {
          // If we need to fetch more data

          const fetchSuccessful = await fetchDashboardData(nextCursor);

          if (fetchSuccessful) {
            // Only increment if fetch was successful
            setCurrentIndex((prevIndex) => prevIndex + 1);
          } else {
            // If fetch wasn't successful, set hasMore to false
            setHasMore(false);
          }
        }
      } else if (direction === "previous" && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    },
    [
      currentIndex,
      dashboardData.length,
      hasMore,
      nextCursor,
      fetchDashboardData,
    ]
  );

  if (loading && dashboardData.length === 0) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (dashboardData.length === 0) return <NoDataState />;

  const currentData = dashboardData[currentIndex];

  // Early return if currentData is undefined
  if (!currentData) {
    return <NoDataState />;
  }

  if (employeeIdError) {
    return (
      <ValidationErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          setIsErrorModalOpen(false);
          navigate("/sign-in");
        }}
        errors={validationErrors}
      />
    );
  }

  const { taskContent, taskDetails, taskerDetails } = currentData;

  const isLastRecord = currentIndex === dashboardData.length - 1 && !hasMore;

  return (
    <div style={styles.taskerDashboard}>
      <div style={styles.mainContent}>
        <div style={styles.taskContent}>
          <div style={styles.taskContentHeader}>
            <div style={styles.headerLeft}>
              <h2 style={styles.heading}>{t("taskContent")}</h2>
            </div>
            <div style={styles.headerRight}>
              <div style={styles.countsContainer}>
                <div style={styles.countItem}>
                  <span style={styles.countValue}>{counts.pendingCount}</span>
                  <span style={styles.countLabel}>Pending</span>
                </div>
                <div style={styles.countDivider} />
                <div style={styles.countItem}>
                  <span style={styles.countValue}>
                    {counts.completedTodayCount}
                  </span>
                  <span style={styles.countLabel}>Today</span>
                </div>
              </div>
              <button style={styles.guidelinesButton}>{t("guidelines")}</button>
            </div>
          </div>
          <div style={styles.audioPlayerContainer}>
            <EnhancedAudioPlayer
              src={taskContent.taskUrl}
              style={styles.audioPlayer}
              onPlaybackDataChange={handlePlaybackDataChange}
            />
          </div>
        </div>
        <div style={styles.detailsColumn}>
          <div style={styles.taskDetails}>
            <h2 style={styles.heading}>{t("taskDetails")}</h2>
            <div style={styles.taskDescription}>
              <h3 style={styles.subheading}>{t("taskDescription")}</h3>
              <p style={styles.descriptionText}>
                {taskDetails.taskDescription}
              </p>
            </div>
            <div style={styles.taskInfo}>
              <div>
                <span style={styles.label}>{t("language")}:</span>
                <span style={styles.labelValue}>{taskDetails.language}</span>
              </div>
              <div>
                <span style={styles.label}>{t("assignedOn")}:</span>
                <span style={styles.labelValue}>{taskDetails.assignedOn}</span>
              </div>
              <div>
                <span style={styles.label}>{t("taskCategory")}:</span>
                <span style={styles.labelValue}>
                  {taskDetails.taskCategory}
                </span>
              </div>
              <div>
                <span style={styles.label}>{t("completedOn")}:</span>
                <span style={styles.labelValue}>
                  {taskDetails.completedOn || "Not completed"}
                </span>
              </div>
              <div>
                <span style={styles.label}>{t("taskType")}:</span>
                <span
                  style={{ ...styles.labelValue, textTransform: "capitalize" }}
                >
                  {taskDetails.taskType}
                </span>
              </div>
            </div>
          </div>
          <div style={styles.taskerDetails}>
            <h2 style={styles.heading}>{t("taskerDetails")}</h2>
            <div style={styles.taskerInfo}>
              <div style={styles.column}>
                <div style={styles.field}>
                  <span style={styles.label}>{t("avgRating")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.avgRating}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("age")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.age}</span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("gender")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.gender}</span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("location")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.location}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("fos")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.fos}</span>
                </div>
              </div>
              <div style={styles.column}>
                <div style={styles.field}>
                  <span style={styles.label}>{t("appLanguage")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.appLanguage}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("voiceLanguage")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.voiceLanguages}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("profession")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.profession}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("highestEducation")}:</span>
                  <span style={styles.labelValue}>
                    {taskerDetails.highestEducation}
                  </span>
                </div>
                <div style={styles.field}>
                  <span style={styles.label}>{t("device")}:</span>
                  <span style={styles.labelValue}>{taskerDetails.device}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.secondaryContent}>
        <RatingSection
          ref={ratingSectionRef}
          ratings={ratings}
          onRatingChange={setRatings}
          t={t}
          ratingParameters={ratingParameters}
          setRatingParameters={setRatingParameters}
          key={`rating-section-${currentData.taskResultId}`}
        />
        <TranscriptSection
          transcript={transcript}
          setTranscript={setTranscript}
          t={t}
        />
      </div>
      <div style={styles.tertiaryContent}>
        <CommentsSection
          selectedComment={selectedComment}
          setSelectedComment={setSelectedComment}
          additionalComment={additionalComment}
          setAdditionalComment={setAdditionalComment}
          t={t}
        />
        <TaskerProfilesSelect
          mediaType={taskContent?.mediaType || "audio"}
          selectedProfiles={selectedProfiles}
          setSelectedProfiles={setSelectedProfiles}
          t={t}
        />
      </div>
      <div style={styles.navigation}>
        <button
          style={{
            ...styles.button,
            ...(currentIndex === 0 ||
            isSubmitting ||
            isRejecting ||
            isTransferring ||
            isPostponing
              ? styles.disabledButton
              : {}),
          }}
          onClick={() => handleNavigation("previous")}
          disabled={
            currentIndex === 0 ||
            isSubmitting ||
            isRejecting ||
            isTransferring ||
            isPostponing
          }
        >
          {t("previous")}
        </button>
        <div style={styles.actionButtons}>
          <button
            style={{
              ...styles.button,
              ...styles.rejectButton,
              ...(!canReject() ||
              isRejecting ||
              isSubmitting ||
              isTransferring ||
              isPostponing
                ? styles.disabledButton
                : {}),
            }}
            onClick={handleReject}
            disabled={
              !canReject() ||
              isRejecting ||
              isSubmitting ||
              isTransferring ||
              isPostponing
            }
          >
            {isRejecting ? "Rejecting..." : t("reject")}
          </button>
          <button
            style={{
              ...styles.button,
              ...styles.postponeButton,
              ...(isPostponing || isSubmitting || isRejecting || isTransferring
                ? styles.disabledButton
                : {}),
            }}
            onClick={() => setIsPostponeModalOpen(true)}
            disabled={
              isPostponing || isSubmitting || isRejecting || isTransferring
            }
          >
            {isPostponing ? "Postponing..." : t("postpone")}
          </button>
          <button
            style={{
              ...styles.button,
              ...styles.transferButton,
              ...(isTransferring || isSubmitting || isRejecting || isPostponing
                ? styles.disabledButton
                : {}),
            }}
            onClick={handleTransfer}
            disabled={
              isTransferring || isSubmitting || isRejecting || isPostponing
            }
          >
            {isTransferring ? "Transferring..." : t("transfer")}
          </button>
          <button
            style={{
              ...styles.button,
              ...styles.acceptButton,
              ...(!hasListenedToAudio() ||
              isSubmitting ||
              isRejecting ||
              isTransferring ||
              isPostponing
                ? styles.disabledButton
                : {}),
            }}
            onClick={handleSubmit}
            disabled={
              !hasListenedToAudio() ||
              isSubmitting ||
              isRejecting ||
              isTransferring ||
              isPostponing
            }
          >
            {isSubmitting ? "Submitting..." : t("accept")}
          </button>
        </div>
        <button
          style={{
            ...styles.button,
            ...(isLastRecord ||
            isSubmitting ||
            isRejecting ||
            isTransferring ||
            isPostponing
              ? styles.disabledButton
              : {}),
          }}
          onClick={() => handleNavigation("next")}
          disabled={
            isLastRecord ||
            isSubmitting ||
            isRejecting ||
            isTransferring ||
            isPostponing
          }
        >
          {t("next")}
        </button>
      </div>
      <ValidationErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        errors={validationErrors}
      />
      <PostponeModal
        isOpen={isPostponeModalOpen}
        onClose={() => setIsPostponeModalOpen(false)}
        onSubmit={handlePostpone}
      />
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={closeConfirmationModal}
        onConfirm={confirmationModal.onConfirm}
        title={confirmationModal.title}
        message={confirmationModal.message}
        isLoading={confirmationModal.isLoading}
        confirmText="Confirm"
        cancelText="Cancel"
      />
    </div>
  );
};

const styles = {
  guidelinesButton: {
    padding: "0.25rem 0.5rem",
    border: "none",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: "600",
    cursor: "pointer",
    backgroundColor: "#4299e1",
    color: "white",
    transition: "background-color 0.3s",
  },
  detailsColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  taskDetails: {
    border: "1px solid #e2e8f0",
    padding: "0.5rem",
    flex: "1 1 50%",
    overflowY: "none",
  },

  descriptionText: {
    marginTop: "0.25rem",
    fontSize: "0.875rem",
  },
  comments: {
    width: "30%",
  },
  commentInputs: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  select: {
    padding: "0.25rem",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    fontSize: "0.875rem",
    width: "100%",
  },
  textarea: {
    padding: "0.25rem",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    fontSize: "0.875rem",
    minHeight: "4rem",
    resize: "vertical",
    width: "100%",
  },
  rejectButton: {
    backgroundColor: "#e53e3e",
  },
  postponeButton: {
    backgroundColor: "#ed8936",
  },
  transferButton: {
    backgroundColor: "#38a169",
  },
  acceptButton: {
    backgroundColor: "#38a169",
  },

  actionButtons: {
    display: "flex",
    gap: "0.25rem",
  },
  heading: {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginBottom: "0.15rem",
  },
  subheading: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  taskInfo: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    fontSize: "0.875rem",
    marginTop: "0.25rem",
  },
  transcriptDescription: {
    fontSize: "0.875rem",
    marginBottom: "0.5rem",
  },
  transcriptBox: {
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    padding: "0.5rem",
    fontSize: "0.875rem",
    width: "90%",
    height: "calc(100% - 4rem)",
    resize: "none",
    overflowY: "auto",
  },
  fullWidthInfo: {
    gridColumn: "1 / -1",
    display: "flex",
    alignItems: "flex-start",
  },

  leftColumn: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  },
  rightColumn: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  },

  button: {
    padding: "0.25rem 0.5rem",
    border: "none",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: "600",
    cursor: "pointer",
    transition: "background-color 0.3s, transform 0.1s",
    outline: "none",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#3182ce",
    color: "white",
  },
  disabledButton: {
    backgroundColor: "#a0aec0",
    cursor: "not-allowed",
    opacity: 0.6,
    boxShadow: "none",
  },

  locationContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
  },
  locationText: {
    margin: 0,
    marginLeft: "0.5rem",
    fontSize: "0.875rem",
    color: "#2c5282",
  },

  taskContent: {
    border: "1px solid #e2e8f0",
    padding: "1rem",
    backgroundColor: "#f7fafc",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    height: "90%", // Make it full height
  },
  taskContentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  audioPlayerContainer: {
    flex: 1, // This will make it take up all available space
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#edf2f7",
    borderRadius: "8px",
    padding: "1rem",
  },
  audioPlayer: {
    width: "100%",
    maxWidth: "500px", // You can adjust this value as needed
  },
  taskerDetails: {
    border: "1px solid #e2e8f0",
    padding: "0.5rem",
    flex: "1 1 50%",
    overflowY: "auto",
  },
  taskerInfo: {
    display: "flex",
    gap: "1rem",
    fontSize: "0.875rem",
  },
  column: {
    flex: "1 1 50%",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  field: {
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    fontWeight: "bold",
    marginRight: "0.5rem",
    minWidth: "150px", // Increased to accommodate longer labels
    flexShrink: 0,
  },
  labelValue: {
    backgroundColor: "rgba(66, 153, 225, 0.1)",
    padding: "0.25rem 0.5rem",
    borderRadius: "6px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    flex: 1,
  },

  secondaryContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    marginTop: "0rem",
    minHeight: "80%",
  },

  tertiaryContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    marginTop: "1rem",
    minHeight: "80%",
  },

  taskerDashboard: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "white",
    color: "black",
    padding: "0.5rem",
    boxSizing: "border-box",
    gap: "0.25rem",
  },

  // Update mainContent height
  mainContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    minHeight: "40vh",
  },

  // Update navigation margin
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "auto",
    paddingTop: "1rem",
  },

  // for task content header
  // Add to your styles object

  countItem: {
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
  },
  countValue: {
    fontSize: "0.875rem",
    fontWeight: "700",
    color: "#2563eb",
  },
  countLabel: {
    fontSize: "0.75rem",
    color: "#64748b",
  },
  countDivider: {
    width: "1px",
    height: "16px",
    backgroundColor: "#e2e8f0",
  },

  headerLeft: {
    display: "flex",
    alignItems: "center",
  },

  headerRight: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },

  countsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
    backgroundColor: "#f8fafc",
    border: "1px solid #e2e8f0",
    borderRadius: "6px",
    padding: "0.25rem 0.75rem",
    height: "32px", // Match the height of guidelines button
  },
};

export default TaskerDashboard;
