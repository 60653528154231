import React from "react";
import { useNavigate } from "react-router-dom";
import "./NavbarStyles.css";

const Navbar = ({ toggleSidebar, isSidebarOpen }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const handleLogout = () => {
    // Clear all items from localStorage
    localStorage.removeItem("userName");
    localStorage.removeItem("employeeId");
    localStorage.removeItem("menuAccess");

    // Redirect to sign-in page
    navigate("/sign-in");
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <button className="menu-button" onClick={toggleSidebar}>
          {isSidebarOpen ? "×" : "☰"}
        </button>
        <span className="navbar-title" onClick={handleClick}>
          The Data Taskers
        </span>
      </div>

      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </nav>
  );
};

export default Navbar;
